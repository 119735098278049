@import url(https://fonts.googleapis.com/css2?family=Cairo:wght@200;300;400&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@100&display=swap");
*::-webkit-scrollbar {
    height: 10px;
    width: 10px;
}

*::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

*::-webkit-scrollbar-thumb {
    border-radius: 5px;
    background-color: rgba(54, 54, 54, 0.5);
}

a {
    color: rgb(216, 216, 216);
    text-decoration: none; /* no underline */
  }
